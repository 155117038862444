/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@1.11.3/dist/jquery.min.js
 * - /npm/lodash@4.17.20/lodash.min.js
 * - /npm/gsap@3.5.1/dist/gsap.min.js
 * - /npm/flickity@2.2.1/dist/flickity.pkgd.min.js
 * - /npm/flickity-imagesloaded@2.0.0/flickity-imagesloaded.min.js
 * - /npm/flickity-fullscreen@1.1.1/fullscreen.min.js
 * - /npm/flickity-fade@1.0.0/flickity-fade.min.js
 * - /npm/flickity-bg-lazyload@1.0.1/bg-lazyload.min.js
 * - /npm/shopify-cartjs@1.1.0/dist/cart.min.js
 * - /npm/fastclick@1.0.6/lib/fastclick.min.js
 * - /npm/lodash-uuid@0.0.3/index.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
